<template>
  <div>
    <h1>
      Projects I've Been Up To
    </h1>
    <article>
      <p>I've had the chance to dive into some amazing projects that have been a big part of my journey. Here are some of the highlights.</p>
      <ul class="timeline">
        <li class="timeline-event">
          <label class="timeline-event-icon"></label>
          <div class="timeline-event-copy">
            <p class="timeline-event-thumbnail">July 2024 - Present</p>
            <h3>Advancing Generative AI in Personalized Learning</h3>
            <h4>Associated with <a href="https://psym-ds.github.io/"><b>PDS Lab</b></a></h4>
            <img src="/assets/img/pds.png" style="height:14vh;">
            <p>Working with Prof. Jinsong Chen, I am building an AI-driven educational item bank platform from scratch using Vue.js, Flask, and Firestore. This project leverages generative AI, NLP, information retrieval, and recommendation algorithms to enhance personalized learning. The platform tailors recommendations based on each learner’s past performance and the relevance of materials, helping instructors assign items that align with students' individual needs.</p>
            <div class="scroll-container project">
              <img src="/assets/img/PDS_Screenshot1.png">
              <img src="/assets/img/PDS_Screenshot2.png">
              <img src="/assets/img/PDS_Screenshot3.png">
              <img src="/assets/img/PDS_Screenshot4.png">
            </div>
          </div>
        </li>
        <li class="timeline-event">
          <label class="timeline-event-icon"></label>
          <div class="timeline-event-copy">
            <p class="timeline-event-thumbnail">May 2024 - Present</p>
            <h3>Computational Model for Modelling Human Memory Systems and Learning Behaviors</h3>
            <h4>Associated with <a href="https://slrlab.edu.hku.hk/"><b>SLR Lab</b></a></h4>
            <img src="/assets/img/slr.png" style="height:14vh;">
            <p>Under the supervision of Dr. Man-kit Lee, I am investigating human memory systems and learning behaviors. My work involves developing a computational model to simulate these processes, experimenting with various machine learning and neural network models to represent the implicit and explicit learning systems in the human brain. Alongside this, I am conducting literature reviews on artificial neural networks, reinforcement learning, and cognitive neuroscience to deepen the model's foundations.</p>
            <div class="scroll-container project">
              <img src="/assets/img/SLR_Screenshot1.png">
              <img src="/assets/img/SLR_Screenshot2.png">
              <img src="/assets/img/SLR_Screenshot3.png">
            </div>
          </div>
        </li>
        <li class="timeline-event">
          <label class="timeline-event-icon"></label>
          <div class="timeline-event-copy">
            <p class="timeline-event-thumbnail">December 2023 - May 2024</p>
            <h3>BRIGHT: A Benchmark for Reasoning-Intensive Information Retrieval</h3>
            <h4>Associated with <a href="https://www.xlang.ai/"><b>XLANG Lab</b></a></h4>
            <img src="/assets/img/xlang.png" style="height:6vh;">
            <p>I had an opportunity to work on BRIGHT, a benchmark designed to evaluate how well models can reason through complex queries. My responsibilities included conducting literature reviews, gathering and analyzing data from public forums, creating query-document pairs, and testing coding-related queries.</p>
            <div class="scroll-container project">
              <img src="/assets/img/BRIGHT1.png">
              <img src="/assets/img/BRIGHT2.png">
              <img src="/assets/img/BRIGHT4.png">
              <img src="/assets/img/BRIGHT5.png">
            </div>
            <p><a href="https://arxiv.org/abs/2407.12883">BRIGHT: A Realistic and Challenging Benchmark for Reasoning-Intensive Retrieval</a><br>Hongjin Su*, Howard Yen*, Mengzhou Xia*, Weijia Shi, Niklas Muennighoff, <b>Han-yu Wang</b>, Haisu Liu, Quan Shi, Zachary S. Siegel, Michael Tang, Ruoxi Sun, Jinsung Yoon, Sercan O. Arik, Danqi Chen, Tao Yu<br><i>Preprint</i></p>
            <p>For additional details including the paper, code, data, and more, please visit the homepage of this project: <a href="https://brightbenchmark.github.io/">BRIGHT</a></p>
          </div>
        </li>
        <li class="timeline-event">
          <label class="timeline-event-icon"></label>
          <div class="timeline-event-copy">
            <p class="timeline-event-thumbnail">September 2017 - June 2022</p>
            <h3>My Early Projects in Junior High and High School</h3>
            <h4>Associated with <a href="https://www.shsid.org/"><b>SHSID</b></a></h4>
            <div class="image-wrapper">
                <img src="/assets/img/shsid.png" style="height:7.5vh;">
            </div>
            <p>During my junior high and high school years, I got to work on a bunch of projects that really sparked my love for coding. Some of the highlights were creating a batch downloader for Scientific American episodes, developing a few Chrome extensions, building a website for Kiwiview International Limited, and making an economics graph generator called Econographer. These projects show just how excited I was (and still am) about programming and solving problems.</p>
            <div class="scroll-container project">
              <img src="/assets/img/econographer1.png">
              <img src="/assets/img/musicdownloader3.jpg">
              <img src="/assets/img/chromeextension1.png">
              <img src="/assets/img/safetcher1.jpg">
              <img src="/assets/img/24.png">
            </div>
            <p>For more details, please visit: <a><router-link to="/early">My Early CS Adventures</router-link></a>.</p>
          </div>
        </li>
      </ul>  
    </article>
  </div>
</template>

<script>
export default {
  name: 'ProjectsTab'
}
</script>

<style scoped>
    @import url('../assets/css/list.css');
</style>