<template>
  <div>
    <h1>
      My Work Experience
    </h1>
    <article>
      <p>I've taken on various roles that have shaped who I am today. Come along as I share these key experiences with you!</p>
      <div id="timeline">
        <div>
          <section class="year">
            <h3>2024</h3>
            <section>
              <h4>August 2024 - December 2024</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hsbc.png" style="height:5.5vh;">
                  </div>
                <li class="title">Digital Transformation Intern</li>
                <li class="role">Hongkong and Shanghai Banking Corporation Limited</li>
                <li class="description">I am researching applications of AI and Generative AI in Wealth and Personal Banking to find ways these can benefit customers and boost efficiency.</li>
                <li class="description">Working on digital transformation plans to create a smoother and more personalized customer experience in WPB.</li>
                <li class="description">Building enhanced Excel sheets with Visual Basic to streamline data automation and bring data to life visually.</li>
              </ul>
            </section>
            <section>
              <h4>July 2024 - April 2025</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hku.jpeg" style="height:8.5vh;">
                  </div>
                <li class="title">Part-time Student Research Assistant</li>
                <li class="role">Psychometrics and Data Science Lab, HKU</li>
                <li class="description">I am exploring how generative AI, NLP, and recommendation algorithms can make personalized learning smarter and more accessible.</li>
                <li class="description">Currently developing an item bank platform from scratch using Vue.js, Flask, and Firestore database.</li>
                <li class="description">Created a portfolio website for the PDS Lab using Jekyll. Feel free to check it out here: <a href="https://psym-ds.github.io/">PDS Laboratory</a>.</li>
              </ul>
            </section>
            <section>
              <h4>May 2024 - December 2024</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hku.jpeg" style="height:8.5vh;">
                  </div>
                <li class="title">Part-time Student Research Assistant</li>
                <li class="role">Speech, Language, and Reading Lab, HKU</li>
                <li class="description">I am developing machine learning models to simulate human memory systems and understand learning behaviors.</li>
                <li class="description">Analyzing how people and models learn over time using experimental data, exploring where human and machine learning intersect.</li>
                <li class="description">Delving deeply into reinforcement learning, deep learning, and cognitive neuroscience through literature reviews.</li>
              </ul>
            </section>          
          </section>
          <section class="year">
            <h3>2023</h3>
            <section>
              <h4>June 2023 - December 2023</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/kiwiview.png" style="height:9vh;">
                  </div>
                <li class="title">Web Developer and Data Analyst</li>
                <li class="role">Kiwiview International Limited</li>
                <li class="description">I led the design, development, and hosting of the company website with PHP and MySQL.</li>
                <li class="description">Collected and visualized global trends in study and travel using Python.</li>
                <li class="description">Worked on strategies to grow the custom travel market and attract new customers, which was a valuable learning experience in business development.</li>
              </ul>
            </section>
          </section>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ExperienceTab'
}
</script>

<style scoped>
    @import url('../assets/css/timeline.css');
</style>